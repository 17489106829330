import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, withPrefix } from "gatsby"
import Img from "gatsby-image"
import loadScript from "../components/loadScript"
import { injectIntl } from "gatsby-plugin-intl";

import ContactFormProductDetail from '../components/propertyDetail/contactFormProductDetail'

class ContactUs extends React.Component {

    loadMapScript() {
        var self = this;
        // Load the google maps api script when the component is mounted.
        loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAhoXB4coOFXrs11hlbfNWl5A6hWCtDJVY')
          .then((script) => {
              self.loadMap();
          })
          .catch((err) => {
            console.error(err.message);
          });
    }

    loadMap(){
        var myOptions = {
            zoom: 17,
            center: new window.google.maps.LatLng(28.0843995, -16.7230046),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            gestureHandling: 'cooperative',
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
        }
        var img_icon = '/theme/img/map-marker-2.png'
        var map = new window.google.maps.Map(document.getElementById("map-canvas"), myOptions);
        new window.google.maps.Marker({
            map: map,
            icon: img_icon,
            position: new window.google.maps.LatLng(28.0843995, -16.7230046)
        });
    }

    componentDidMount(){   
        if (!window.google) {
            this.loadMapScript();
        }
        else if (!window.google.maps) {
            this.loadMapScript();
        }
        else{
            this.loadMap();
        }
    }

    render(){
        var property = {
            id: null
        };
            return(
                <Layout>
                    <SEO title="Contact us" />
                    <section>
                        <div className="container mainTitle">
                            <h2>{this.props.intl.formatMessage({ id:'Contact-us-title'})}</h2>
                        </div>

                        <div className="site-breadcrumb">
                            <div className="container">		
                            </div>
                        </div>

                        <section className="page-section blog-page">
                            <div className="container">
                                <div className="row">
                                    <div 
                                        className="col-lg-6"
                                        style={{
                                            textAlign: "center",
                                            paddingBottom: 30
                                        }}
                                    >
                                        <Img alt="" fluid={this.props.data.contactImage.childImageSharp.fluid} />
                                        <br/>
                                        {
                                            this.props.intl.formatMessage({ id:'Contact-person'}) !== "Contact-person" && 
                                            <h5
                                                style={{fontSize: 20}}
                                            >
                                                {this.props.intl.formatMessage({ id:'Contact-person'})}
                                            </h5>
                                        }
                                        {
                                            this.props.intl.formatMessage({ id:'Contact-person2'}) !== "Contact-person2" && 
                                            <h5
                                                style={{fontSize: 20}}
                                            >
                                                {this.props.intl.formatMessage({ id:'Contact-person2'})}
                                            </h5>
                                        }
                                        {
                                            this.props.intl.locale === "pl" &&
                                            <a rel="noopener noreferrer"
                                                href={withPrefix('/emilia-certificate.pdf')}
                                                target="_blank"
                                            >
                                                <Img className="contact-certificate" fluid={this.props.data.certificate.childImageSharp.fluid} />
                                            </a>
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contact-right">
                                            <ContactFormProductDetail property={property} intl={this.props.intl}></ContactFormProductDetail>
                                        </div>
                                    </div>
                                </div>
                                <div id="map-canvas"></div>
                            </div>
                        </section>
                    </section>
                </Layout>
            )
        }

    }

export default injectIntl(ContactUs)
export const query = graphql`
query {
    contactImage: file(relativePath: { eq: "contact-us.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            }
        }
    },
    certificate: file(relativePath: { eq: "team/certificate.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`